// Imports from respective modules
import  { DataProcessing, PDS, QA }  from "@mydex/pds-adaptor-frontend";
import  { MRD, Lists }  from "@mydex/mrd-adaptor-frontend";
import  { ClientList }  from "@mydex/inapp-datasets";
import  { Util, Validation }   from "@mydex/utils";
import  { Dashboard }   from "@mydex/dashboards";
import  { MydexDesignSystem }   from "@mydex/mydex-design-system";
import  { Offers }  from './Offers';

// Loads code into the window context so can be references anywhere
window.DataProcessing = DataProcessing;
window.QA = QA;
window.PDS = PDS;
window.MRD = MRD;
window.Lists = Lists;
window.ClientList = ClientList;
window.Util = Util;
window.Validation = Validation;
window.Dashboard = Dashboard;
window.Offers = Offers;

// Create click handlers for cards on a page. 
window.addEventListener("load", MydexDesignSystem.init, false);
window.addEventListener("load", DataProcessing.load.pds, false);
window.addEventListener("load", Offers.eventListeners, false);
window.addEventListener("load", Validation.eventListeners, false);
window.addEventListener("load", QA.eventListeners, false);