/**
 * Offers.js handles offers for Citizen inclued
 */
export const Offers = (function () {

    var eventListeners = function () {
        $('.myds-counter-sign #submit').on('click', function (e) {
            e.preventDefault();
            forms.completeSignature();
        });

        $('#yes .myds-offer-yes').on('click', function (e) {
            offer.response.yes();
        });

        $('#no .myds-offer-no').on('click', function (e) {
            e.preventDefault();
            offer.response.no();
        });

        $('#maybe .myds-offer-maybe').on('click', function (e) {
            e.preventDefault();
            offer.response.maybe();
        });

        if ($('#myds-inclued-page-offers').length) {
            offersList.navigate();
        }

        if ($('#myds-inclued-page-offer').length) {
            offer.load();
        }

    }
    /**
     * Navigation in the Offer(s) pages
     */
    var offer = {

        data: [],

        load: function() {
            
            $.ajax({
                url: '/api' + window.location.pathname,
                type: 'get'
            }).done(function (response) {

                // TODO: Validate Response
                offer.data = response;
                offer.presentation.display(offer.data)

            }).fail(function (e) {
                console.log(e);
            });

        },

        /**
         * Variable to hold presentation functionality
         */
        presentation: {

            /**
             * Display when loading the page
             */
            display: function () {
                offer.presentation.removeTicks();
                this.breadcrumbs.show()
                this.icon.update();
                var offer_status = JSON.parse(offer.data.status);

                // Check the status of the offer
                if (offer_status.response == "YES") {

                    if (offer_status.form_status == "COMPLETE"){

                        // if offer/insight is complete only show the complete text - not the acceptance text
                        offer.response.showAccepted(true);
                        $('#yes-response-icon').addClass('d-inline-block');

                    }else{
                        offer.response.showAccepted();
                    $('#yes-response-icon').addClass('d-inline-block');
                    }
                    
          
                } else {
                    
                    var presentation_text = $('#acceptance_text').html(offer.data.offer.presentation_text)
                    $('#offerContent').append(presentation_text);
                    $('.options-select').removeClass('d-none');
                    $('#offer-icon').removeClass('d-none');

                    if (offer_status.response === "NO") {
                        $('#no-response-icon').addClass('d-inline-block');
                    } else if (offer_status.response === "MAYBE") {
                        $('#maybe-response-icon').addClass('d-inline-block');
                    }
                }
            },

            /** Display the breadcrumbs on the offers page
             *  If there is a short_name in the DB, this will be used
             */
            breadcrumbs: {

                show: function () {

                    if (offer.data.offer.short_name) {
                        $('#breadcrumb-name').append(offer.data.offer.short_name);
                    } else {
                        $('#breadcrumb-name').append(offer.data.offer.name);
                    }

                }
            },

            icon: {

                update: function () {

                    $('#icon').attr("src", offer.data.offer.icon);

                }
            },

            /** 
             * Removes all ticks from options
             */
            removeTicks: function () {
                $('#no-response-icon').addClass('d-none');
                $('#yes-response-icon').addClass('d-none');
                $('#maybe-response-icon').addClass('d-none');
                $('#maybe-response-icon').removeClass('d-inline-block');
            }
        },

        /** 
         * Variable for functions related to when the 
         * member selects an option  
         * */
        response: {

            showAccepted: function(complete){
                if (complete){
                    var acceptance_text = $('#acceptance_text').html(offer.data.offer.completed_text);
                    $('#no').addClass('d-none');
                    $('#maybe').addClass('d-none');
                    $('#yes').addClass('form-complete');
                    $('#yes-link').text('Review Answers'); 
                }else{
                    var acceptance_text = $('#acceptance_text').html(offer.data.offer.acceptance_text);
                }
                $('#offerContent').append(acceptance_text); 
                $('.options-select').removeClass('d-none');
                $('#acceptedOffer').show();  
                $('#offer-icon').removeClass('d-none');
                $('#accepted-icon').show(); 
                $('.i-custom').addClass('d-inline-block');
                $('#yes-response-icon').addClass('d-inline-block');
            },

            yes: function () {
                Offers.offer.presentation.removeTicks();
                this.showAccepted();
                var is_complete = $('#yes').hasClass('form-complete');
                if (is_complete == false){
                    this.postOfferResponse("YES");
                }
            },

            no: function () {
                Offers.offer.presentation.removeTicks();
                window.location.replace("/offers");
                this.postOfferResponse("NO");
            },

            maybe: function () {
                Offers.offer.presentation.removeTicks();
                window.location.replace("/offers");
                this.postOfferResponse("MAYBE");
            },
            // Helper Functions
            postOfferResponse: function (offer_response) {

                // Get the csrf tokens from the page
                var csrf = Util.getCSRF();

                $.ajax({
                    url: window.location.pathname + '/response',
                    type: 'post',
                    data: {
                        offer_response:offer_response
                    },
                    headers: { 'x-csrf-token': csrf },
                }).done(function (response) { //
                    
                }).fail(function (e) {
                    console.log(e);
                });
            },
          
        }
    }

    var offersList = {

        navigate: function () {

            $('.card-body').click(function () {

                var offer = this.querySelectorAll('#offer');
                var id = offer[0].getAttribute("data-id");
                window.location.replace("offers/" + id);

            });
        },
    }
    /**
     * Sets the form_status to AWAITING_SI
     */
    var forms = {

        completeSignature: function () {

            var request = $.ajax({
                url: window.location.pathname,
                type: 'POST'
            });

            request.then(function (response) {
                window.location = '/my-account'
            });
        },

        /**
         * Sets the form status of this offers_members to COMPLETE
         * Used from /offers/1/ema-form
         */
        completeForm: function () {
            var csrf = Util.getCSRF();

            return $.ajax({
                url: window.location.pathname + '/complete',
                type: 'POST',
                headers: { 'x-csrf-token': csrf }
            });
        }
    }

    return {
        offer: offer,
        forms: forms,
        offersList: offersList,
        eventListeners: eventListeners
    }

}());